// Default State
const initialState = {
  snack: null,
  alert: null
};

// Reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_SNACK':
      return { ...state, snack: action.data };
    case 'HIDE_SNACK':
      return { ...state, snack: null };
    case 'SHOW_ALERT':
      return { ...state, alert: action.data };
    case 'HIDE_ALERT':
      return { ...state, alert: null };
    default:
      return state;
  }
}

// Exports Default
export default reducer;
