import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { 
  Container, LinearProgress
} from '@mui/material';
import { dbRef } from '../../utils/firebase';
import { get } from '../../utils/api';

import Header from '../../components/Header';
import Page from '../../components/Page';

import Search from './Search';
import Job from './Item';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Jobs() {
  const [jobs, setJobs] = useState([])
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [salary, setSalary] = useState(50);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    get(`jobs/categories`).then((res) => {
      setCategories(res.data);
      setCategory(res.data[0]._id);
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    if (category) {
      setLoading(true);
      get(`jobs/search?category=${category}`).then((res) => {
        setJobs(res.data);
        setLoading(false);
      });
    }
  }, [category])

  // useEffect(() => {
  //   setSalary(Number(query.get('salary')) || 0);
  // }, [query])

  // const updateQuery = (v) => {
  //   history.replace({
  //     pathname: 'jobs',
  //     search: `?salary=${v}`
  //   })
  // }

  return (
    <Page title="Jobs | Agency">
      <Container maxWidth="lg">
        <Outlet />
        <Routes>
          <Route exact path="/" element={
            <Search
              categories={categories}
              category={category}
              setCategory={setCategory}
              jobs={jobs} 
              salary={salary}
              setSalary={setSalary}
              loading={loading}
            />
          }>
          </Route>
          <Route exact path="/:jobId" element={<Job />} />
        </Routes>
      </Container>
    </Page>
  )
}