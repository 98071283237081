export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const displayMoney = (lang, withUnit=true) => (m) => {
  const man = m * 100
  if (lang.startsWith("ko")) {
    if (man < 10000) {
      return `${numberWithCommas(man)} 만${withUnit?"원":""}`
    }
    else {
      if (man % 10000 == 0) {
        return `${Math.floor(man / 10000)} 억${withUnit?"원":""}`
      }
      else {
        return `${Math.floor(man / 10000)} 억 ${numberWithCommas(man % 10000)} 만${withUnit?"원":""}`
      }
    }
  }
  else if (lang.startsWith("en")) {
    if (m >= 1)
      return `${m} M ${withUnit?"KRW":""}`
    else
      return `${m * 1000} K ${withUnit?"KRW":""}`
  }
}

export const humanFileSize = (bytes, dp=1) => {
  const thresh = 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

export const objectToArray = obj => {
  return Object.keys(obj).map(key => {
    return {
      id: key,
      ...obj[key]
    }
  })
}