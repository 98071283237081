import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  Box, Card, Typography,
  ListItem, Avatar, Grid, CircularProgress, Chip,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { displayMoney } from '../../utils/functions';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  slider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  button: {
    fontSize: "1.3rem"
  }
}))

const CardStyle = styled(Card)(({ theme }) => ({
  margin: theme.spacing(2)
}));

export const JobItem = ({job, onClick}) => {
  const { t, i18n } = useTranslation();
  const text = `${displayMoney(i18n.language, false)(job.salary[0])} ~ ${displayMoney(i18n.language)(job.salary[1])}`
  return (
    <CardStyle>
      <ListItem
        button
        onClick={onClick}
      >
        <Avatar src={job.company.logo} sx={{mr: 2}} />
        <Box>
          <Box minHeight={75}>
            <Typography variant="h5">{job.company.name} </Typography>
            <Typography variant="body2" sx={{whiteSpace: "pre-line"}}>{job.title}</Typography>
          </Box>
          <Typography color="#000" sx={{fontWeight: 'bold', whiteSpace: "pre-line"}}>{text}</Typography>
        </Box>
      </ListItem>
    </CardStyle>
  )
}

export default function Search({ jobs, categories, category, setCategory, loading }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [sortBy, setSortBy] = useState('salaryMinDesc');
  const sortByList = [
    'salaryMinDesc',
    'salaryMaxDesc',
    'salaryMinAsc',
    'salaryMaxAsc'
  ];

  const sortKey = sortBy.startsWith('salaryMin') ? 0 : 1;
  const sortDesc = sortBy.endsWith('Desc')
  const sortedJobs = jobs.sort((a, b) => {
    return (sortDesc ? -1 : 1) * (a.salary[sortKey] - b.salary[sortKey]);
  });

  return (
    <Box>
      <Box mt={3}>
        <Typography variant="h2" gutterBottom>{t('job.search.title')}</Typography>
        <Box my={2}>
          {categories.map((cat) => {
            return (
              <Chip
                key={`job-category-${cat._id}`}
                label={`${t(`job.categories.${cat._id}`)} (${cat.count})`}
                color={cat._id === category ? "primary" : "default"}
                onClick={() => setCategory(cat._id)}
                sx={{ mr: 1, mb: 1, fontSize: '1.1rem' }}
              />
            )
          })}
          {/* <Typography variant="h4" gutterBottom>{t('job.search.salary')}</Typography>
          <Box width="80%" margin="auto" mt={5} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h3" align="center">{displayMoney(i18n.language)(salary)}</Typography>
            <Slider 
              value={salary}
              onChange={(e, v) => setSalary(v)}
              className={classes.slider}
              min={0}
              max={200}
              step={10}
              marks
            />
          </Box> */}
        </Box>
      </Box>
      <Box mt={8}>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">{jobs.length} {t('job.search.result')}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControl variant="standard" sx={{width: "20%", minWidth: 200, my: 2}}>
              <InputLabel>정렬</InputLabel>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
              >
                {sortByList.map((name) => <MenuItem key={`treatment-${name}`} value={name}>{t(`sort.${name}`)}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Box>
          <Grid container>
            {loading ? (
              <Box p={4} display="flex" width="100%" alignItems="center" justifyContent="center">
                <CircularProgress size={80} />
              </Box>
            ) : (
              <>
                {sortedJobs.map(job => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={job._id}>
                      <JobItem 
                        job={job}
                        onClick={() => navigate(`/jobs/${job._id}`)}
                      />
                    </Grid>
                  )
                })}
                {jobs.length == 0 && <Box>
                  <Typography>No results</Typography>
                </Box>}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}