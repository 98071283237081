import axios from 'axios';
import mime from 'mime';
import { firebaseAuth } from './firebase';

const { NODE_ENV } = process.env;
const SERVER_DEV_URL = process.env.REACT_APP_SERVER_DEV_URL;
const SERVER_PROD_URL = process.env.REACT_APP_SERVER_PROD_URL;
const __DEV__ = NODE_ENV === 'development';
const apiUrl = __DEV__ ? SERVER_DEV_URL : SERVER_PROD_URL;

const getToken = async () => {
  const { currentUser } = firebaseAuth();
  let idToken = null;
  if (currentUser) {
    idToken = await currentUser.getIdToken(true);
  }
  return idToken;
};

async function getConfig(method) {
  const idToken = await getToken();
  return {
    method: method === 'POSTFILE' ? 'POST' : method,
    mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Access-Control-Allow-Credentials': true,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': method === 'POSTFILE' ? 'multipart/form-data' : 'application/json',
      Authorization: `Bearer ${idToken}`
    },
    crossorigin: true,
    credentials: 'same-origin'
  };
}

const createFormData = (file, body) => {
  const data = new FormData();
  if (file) {
    data.append('file', file);
  }

  if (body) {
    Object.keys(body).forEach((key) => {
      data.append(key, body[key]);
    });
  }
  return data;
};

export async function get(path, params = {}) {
  if (__DEV__) console.log('GET', apiUrl, path, params);
  const config = await getConfig('GET');
  const result = await axios.get(`${apiUrl}/api/${path}`, {
    ...config,
    params
  });
  return result;
}

export async function post(path, { query, params, body }) {
  const config = await getConfig('POST');
  if (__DEV__) console.log('POST', `${apiUrl}/api/${path}`, body);
  const result = axios.post(`${apiUrl}/api/${path}`, body, config);
  return result;
}

export async function postFile(path, { query, params, file }) {
  const config = await getConfig('POSTFILE');
  const formData = createFormData(file);
  if (__DEV__) console.log('POSTFILE', `${apiUrl}/api/${path}`, formData);
  const result = axios.post(`${apiUrl}/api/${path}`, formData, config);
  return result;
}

export async function put(path, { query, params, body }) {
  const config = await getConfig('PUT');
  if (__DEV__) console.log('PUT', apiUrl, path, body);
  const result = axios.put(`${apiUrl}/api/${path}`, body, config);
  return result;
}

export async function del(path) {
  const config = await getConfig('DELETE');
  if (__DEV__) console.log('DELETE', apiUrl, path);
  const result = axios.delete(`${apiUrl}/api/${path}`, config);
  return result;
}
