import React, { useState } from 'react';
import { Snackbar as MSnackbar, Alert } from '@mui/material';

export default function Snackbar(props) {
  const { open, handleClose, message, severity="success", anchorOrigin={
    vertical: 'top',
    horizontal: 'center',
  }} = props;
  return (
    <MSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </MSnackbar>
  )
}