import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useRoutes } from 'react-router-dom';
import { 
  Container, LinearProgress
} from '@mui/material';
import { dbRef } from '../../utils/firebase';
import { get } from '../../utils/api';

import Header from '../../components/Header';
import Page from '../../components/Page';

import Search from './Search';
import Candidate from './Item';

export default function Candidates() {
  const [candidates, setCandidates] = useState([])
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    get(`candidates/categories`).then((res) => {
      setCategories(res.data);
      setCategory(res.data[0]._id);
      setLoading(false);
    });
  }, [])

  useEffect(() => {
    if (category !== null) {
      setLoading(true);
      get(`candidates/search?category=${category}`).then((res) => {
        setCandidates(res.data);
        setLoading(false);
      });
    }
  }, [category])

  const routes = useRoutes([
    {
      path: "/",
      element: <Search
        categories={categories}
        category={category}
        setCategory={setCategory}
        candidates={candidates} 
        loading={loading}
      />
    },
    {
      path: "/:candidateId",
      element: <Route exact path=":candidateId" element={<Candidate />} />
    }
  ])

  return (
    <Page title="Candidates | Agency">
      <Container maxWidth="lg">
        <Outlet />
        <Routes>
          <Route exact path="/" element={
            <Search
              categories={categories}
              category={category}
              setCategory={setCategory}
              candidates={candidates} 
              loading={loading}
            />
          } />
          <Route exact path="/:candidateId" element={<Candidate />} />
        </Routes>
      </Container>
    </Page>
  )
}