import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import { styled } from '@mui/system';
import { 
  Fab, CircularProgress
} from '@mui/material';

import {
  Assignment as AssignmentIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon
} from '@mui/icons-material';
import { listenResume, unlistenResume } from '../../utils/database';

import UploadeDialog from './UploadDialog';

const Uploader = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [waitingId, setWaitingId] = useState(localStorage.getItem('waiting'));
  const [receivedId, setReceivedId] = useState(localStorage.getItem('received'));

  const status = waitingId ? 1 : receivedId ? 2 : 0;
  console.log('status', status);

  const handleUpload = (resumeKey) => {
    setWaitingId(resumeKey)
    setShowDialog(false);
  };

  useEffect(() => {
    if (waitingId) {
      localStorage.setItem('waiting', waitingId)
      console.log('listening', waitingId);
      listenResume(waitingId, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          if (data.reviewedAt) {
            console.log('unlistening', waitingId);
            unlistenResume(waitingId);
            setReceivedId(waitingId);
            setWaitingId();
          }
        }
        else {
          localStorage.removeItem('waiting');
          setWaitingId();
        }
      });
    }
    else {
      localStorage.removeItem('waiting');
    }
  }, [waitingId])

  useEffect(() => {
    if (receivedId) {
      localStorage.setItem('received', receivedId);
    }
    else {
      localStorage.removeItem('received');
    }
  }, [receivedId])

  const button = [
    {
      msg: "Upload Resume",
      icon: <AssignmentIcon sx={{ mr: 1 }} />,
      onClick: () => setShowDialog(true),
      color: "primary"
    },
    {
      msg: "Wait for feedback",
      icon: <CircularProgress size={18} sx={{mr: 1, mb: 0.5}} />,
      color: "primary"
    },
    {
      msg: "Checkout feedback!",
      icon: <AssignmentTurnedInIcon sx={{ mr: 1 }} />,
      onClick: () => {
        setReceivedId();
        navigate(`/resume/${receivedId}`);
      },
      color: "secondary"
    }
  ]

  return (
    <>
      <UploadeDialog
        open={showDialog}
        onDismiss={() => setShowDialog(false)}
        onComplete={handleUpload}
      />
      <Fab
        onClick={button[status].onClick}
        variant="extended"
        color={button[status].color}
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          m: 2
        }}
        disabled={status == 1}
      >
        {button[status].icon}
        {button[status].msg}
      </Fab>
    </>
  )
}

export default Uploader;