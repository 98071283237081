// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";
import 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB4fniHLB457QvVnS3sVWiKwy7iiHT9ezg",
  authDomain: "agency-9b48b.firebaseapp.com",
  databaseURL: "https://agency-9b48b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "agency-9b48b",
  storageBucket: "agency-9b48b.appspot.com",
  messagingSenderId: "595107971464",
  appId: "1:595107971464:web:94ca56e846450b43e43a3d",
  measurementId: "G-D6ZPB5HCZ5"
};

firebase.initializeApp(firebaseConfig);

export const dbRef = firebase.database().ref();
export const stRef = firebase.storage().ref();
export const firebaseAuth = firebase.auth;
