import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from "react-router-dom";
import { styled } from '@mui/system';
import { 
  Box, Checkbox, Button, Typography, IconButton, 
  Dialog, DialogTitle, DialogContent, Avatar, Card,
  List, ListItem, ListItemText, ListItemIcon,
  FormGroup, FormControlLabel, CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import ReactMarkdown from 'react-markdown';
import Page from '../../components/Page';
import Snackbar from '../../components/Snackbar';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { displayMoney, humanFileSize } from '../../utils/functions';
import { get } from '../../utils/api';

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));

const ProfileStyle = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  [theme.breakpoints.down('md')]: {
    alignItems: "center"
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  marginBottom: theme.spacing(2)
}));

export default function Candidate() {
  const { t, i18n } = useTranslation();
  const { candidateId } = useParams();
  const [candidate, setCandidate] = useState();
  const [snack, setSnack] = useState();
  useEffect(() => {
    get(`candidates/${candidateId}`).then((res) => {
      setCandidate(res.data);
      window.scrollTo(0,0);
    })
  }, [])
  if (candidate) return (
    <Page title={`Candidate: ${candidate.name} | Agency`}>
      <Box>
        <ProfileStyle>
          <AvatarStyle src={candidate.photo} />
          <Typography variant="h2">{candidate.name}</Typography>
          <Typography variant="h4" color="textSecondary">{candidate.job}{candidate.category ? `- ${candidate.category}` : ''}</Typography>
        </ProfileStyle>
        <Box mt={3}>
          <Typography variant="h5">{t('candidate.salary')}</Typography>
          <CardStyle>
            <Typography variant="h6">{displayMoney(i18n.language, true)(candidate.desiredSalary)}</Typography>
          </CardStyle>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">{t('candidate.years')}</Typography>
          <CardStyle>
            <Typography variant="h6">{candidate.years} {t('candidate.year')}</Typography>
          </CardStyle>
        </Box>
        {candidate.note && <Box mt={3}>
          <Typography variant="h5">{t('candidate.note')}</Typography>
          <CardStyle>
            <ReactMarkdown>{candidate.note}</ReactMarkdown>
          </CardStyle>
        </Box>}
      </Box>
      <Snackbar
        open={Boolean(snack)}
        handleClose={() => setSnack()}
        message={snack}
      />
    </Page>
  )
  else return (
    <CircularProgress sx={{m: 3}} />
  )
}