import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from "react-router-dom";
import { styled } from '@mui/system';
import { 
  Box, Checkbox, Typography, IconButton, 
  Dialog, DialogTitle, DialogContent, 
  List, ListItem, ListItemText, ListItemIcon,
  FormGroup, FormControlLabel, TextField, Stack
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import {
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { humanFileSize } from '../../utils/functions';
import { uploadResume } from '../../utils/database';

const DropZoneStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderWidth: 4,
  borderRadius: 10,
  borderColor: "#ddd",
  borderStyle: "dashed",
  color: "#999"
}))

const UploadeDialog = (props) => {
  const { t, i18n } = useTranslation();
  const { open, onDismiss, onComplete } = props;
  const [selected, setSelected] = useState([]);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const onDrop = (files) => {
    setSelected(files)
  }
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    onDrop
  });
  
  const files = selected.map((file, idx) => (
    <ListItem 
      dense 
      key={file.path}
      secondaryAction={
        <IconButton 
          edge="end"
          aria-label="delete"
          onClick={() => {
            setSelected([
              ...selected.slice(0, idx),
              ...selected.slice(idx+1)
            ])
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemIcon>
        <InsertDriveFileIcon />
      </ListItemIcon>
      <ListItemText 
        primary={`${file.path} | ${humanFileSize(file.size)}`}
      />
    </ListItem>
  ));

  const handleUpload = () => {
    setLoading(true);
    uploadResume(selected, email, phone).then((resumeKey) => {
      setLoading(false);
      setSelected([]);
      setAgree(false);
      onComplete(resumeKey);
    })
  }

  return (
    <Dialog 
      fullWidth
      maxWidth="md"
      open={open} 
      onBackdropClick={onDismiss}
    >
      <DialogTitle style={{fontSize: '1.5rem'}}>
        {t("apply.title")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{t("apply.guide1")}</Typography>
        <Typography variant="body2">{t("apply.guide2")}</Typography>
        <Typography variant="body2">{t("apply.guide3")}</Typography>
        <section className="container">
          {selected.length == 0 && <DropZoneStyle {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 80 }}/>
            <p>{t("apply.upload")}</p>
          </DropZoneStyle>}
          {selected.length > 0 && <Box mt={2} mb={2}>
            <Typography variant="h6">{t("apply.files")}</Typography>
            <List>{files}</List>
          </Box>}
        </section>
        {selected.length > 0 && <section>
          <Typography>{t('apply.contact')}</Typography>
          <Stack flexDirection="row">
            <TextField
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              label={t('apply.phone')}
              fullWidth
              sx={{m: 1}}
            />
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label={t('apply.email')}
              fullWidth
              sx={{m: 1}}
            />
          </Stack>
        </section>}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox 
              value={agree}
              onChange={(e, checked) => setAgree(checked)}
            />}
            label={
              <Typography variant="overline"  >
                {t("apply.agreement")}
              </Typography>
            }
          />
        </FormGroup>
        <LoadingButton 
          fullWidth
          loading={loading}
          variant="contained"
          disabled={!agree || selected.length == 0 || (!phone && !email)}
          onClick={handleUpload}
          style={{marginTop: 8}}
        >
          업로드
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}

export default UploadeDialog;