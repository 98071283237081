import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import Footer from '../components/Footer';
import Page from '../components/Page';
import classnames from 'clsx';

const useStyles = makeStyles((theme) => ({
  slide: {
    height: "100%",
    width: "100%",
    background: "#fff"
  },
  title: {
    fontWeight: "bold",
    fontSize: "2rem"
  }
}));

const Silde = ({children, ...props}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100vh"
      p={5}
      mt={8}
      {...props}
    >
      {children}
    </Box>
  )
}

function Home() {
  const classes = useStyles()
  const { t } = useTranslation();
  return (
    <Page title="Home | Agency">
      <Parallax pages={3}>
        <ParallaxLayer
          offset={0}
          speed={0}
          factor={3}
          style={{
            backgroundImage: `url(${"images/bg.jpg"})`,
            backgroundSize: "cover"
          }}
        />
        <ParallaxLayer
          offset={0}
          speed={1}
          sticky={{start: 0, end: 0.4}}
        >  
          <Silde>
            <Typography align="left" variant="h2" style={{fontWeight: "bold", wordBreak: "keep-all"}}>
              {t("home1")}
            </Typography>
          </Silde>
        </ParallaxLayer>

        <ParallaxLayer
          speed={1}
          sticky={{start: 0.8, end: 1.2}}
        >
          <Silde style={{color: "#fff"}}>
            <Typography align="right" variant="h2" style={{fontWeight: "bold", wordBreak: "keep-all", fontStyle: "italic"}}>
              {t("home2")}
            </Typography>
          </Silde>
        </ParallaxLayer>

        <ParallaxLayer
          speed={0}
          offset={2}
        >
          <Silde style={{color: "#fff"}}>
            <Typography align="left" variant="h2" style={{fontWeight: "bold", wordBreak: "keep-all"}}>
              ABOUT OUR SERVICES
            </Typography> 
            <Box mt={4} style={{wordBreak: "keep-all"}}>
              <Typography align="left" variant="h5" paragraph style={{fontWeight: "bold"}}>
                {t("about1")}
              </Typography>
              <Typography align="left" variant="h5" paragraph style={{fontWeight: "bold"}}>
                {t("about2")}
              </Typography>
            </Box>

            <Box mt={10} mb={10}>
              <Typography variant="h5">{t("contact")}</Typography>
              <Button 
                href="mailto:sales@agencykr.com" 
                color="primary" 
                variant="contained" 
                style={{
                  marginTop: 30,
                  textTransform: "none"
                }}
              >
                sales@agencykr.com
              </Button>
            </Box>
          </Silde>
        </ParallaxLayer>
      </Parallax>
    </Page>
  );
}

export default Home;
