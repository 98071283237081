import { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight
} from '@mui/icons-material';
import _ from 'lodash';
import ReactSwipe from 'react-swipe';
import firebase from 'firebase/app';
import "firebase/database";
import { numberWithCommas, displayMoney } from '../utils/functions';
import Header from '../components/Header';
import Page from '../components/Page';

const useStyles = makeStyles((theme) => ({
  slide: {
    height: "100vh",
    width: "100%",
    background: "#fff"
  },
  landing: {
    background: `url(${"/images/landing.jpg"})`,
    backgroundSize: "cover",
    height: "100%",
  },
  summary: {
    background: `url(${"/images/summary.jpg"})`,
    backgroundSize: "cover",
    height: "100%",
  },
  innerLanding: {
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: 10
  },
  title: {
    fontWeight: "bold",
    fontSize: "2rem"
  },
  iconButton: {
    width: theme.spacing(12),
    height: theme.spacing(12)
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const occupations = [
  "r&d",
  "pm",
  "marketing",
  "sales",
  "finance",
  "hr",
  "legal",
  "it",
  "manufacturing",
  "chemical",
  "retail",
  "service",
  "pharmaceutical",
  "realestate",
  "financialservice",
  "consulting"
]

const locations = ["강남", "광화문(종로)", "여의도", "판교", "부산", "제주도", "어디든"]

const NumberQuestion = ({num, question, unitFunc, min, max, step, value, onChange}) => {
  const { i18n } = useTranslation();
  const isEng = i18n.language.startsWith("en")
  const isKor = i18n.language.startsWith("ko")
  return (
    <Box width="100%" pt={4} pb={4} display="flex" flexDirection="column" alignItems="flex-start">
      <Typography align="left" variant="h6">{num}. {question}</Typography>
      <Box width="100%" p={2}>
        <Typography align="center" variant="h3">{isEng && value == max ? "more than " : ""}{unitFunc(value)}{isKor && value == max ? " 이상" : ""}</Typography>
      </Box>
      <Slider
        value={value}
        onChange={onChange}
        step={step}
        min={min}
        max={max}
      />
    </Box>
  )
}

const CategoryQuestion = ({num, question, categories, value, translate=true, onChange}) => {
  const { t } = useTranslation();
  return (
    <Box width="100%" pt={4} pb={4} display="flex" flexDirection="column" alignItems="flex-start">
      <Typography align="left" variant="h6">{num}. {question}</Typography>
      {value && <Box width="100%" p={2}>
        <Typography align="center" variant="h3">{translate ? t(`survey.answers.${value}`) : value}</Typography>
      </Box>}
      <Box display="flex" flexWrap="wrap">
        {categories.map(c => {
          return (
            <Chip 
              key={c} 
              label={translate ? t(`survey.answers.${c}`) : c} 
              color={value==c ? "primary" : "default"}
              style={{margin: 3}}
              onClick={() => onChange(c)} 
            />
          )
        })}
      </Box>
    </Box>
  )
}

const MultiCategoryQuestion = ({num, question, categories, values, translate=true, onChange}) => {
  const { t } = useTranslation();
  return (
    <Box width="100%" pt={4} pb={4} display="flex" flexDirection="column" alignItems="flex-start">
      <Typography align="left" variant="h6">{num}. {question}</Typography>
      <Box width="100%" p={2}>
        <Typography align="center" variant="h3">
          {values.map(value => translate ? t(`survey.answers.${value}`) : value).join(', ')}
        </Typography>
      </Box>
      <Box display="flex" flexWrap="wrap">
        {categories.map(c => {
          return (
            <Chip 
              key={c} 
              label={translate ? t(`survey.answers.${c}`) : c} 
              color={values.includes(c) ? "primary" : "default"}
              style={{margin: 3}}
              onClick={() => onChange(c)} 
            />
          )
        })}
      </Box>
    </Box>
  )
}


const SurveyPage = ({page, data, setData}) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language.startsWith("en")
  const isKor = i18n.language.startsWith("ko")
  const updateData = (key, value) => {
    setData({
      ...data,
      [key]: value
    })
  }

  return (
    <Container maxWidth="sm" style={{height: "100%", overflow: "auto"}}>
      <Box pt={12} pb={12} width="100%" minHeight="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {page == 0 && <NumberQuestion
          num={1}
          question={t('survey.questions.0')}
          unitFunc={v => `${v} ${t('survey.year', {count: Number(data.period)})}`}
          min={0}
          max={30}
          step={1}
          value={data.period}
          onChange={(e, v) => updateData("period", v)}
        />}
        {page == 1 && <NumberQuestion
          num={2}
          question={t('survey.questions.1')}
          unitFunc={displayMoney(i18n.language)}
          min={10}
          max={200}
          step={10}
          value={data.salary}
          onChange={(e, v) => updateData("salary", v)}
        />}
        {page == 2 && <CategoryQuestion 
          num={3}
          question={t('survey.questions.2')}
          categories={occupations}
          value={data.type}
          onChange={v => updateData("type", v)}
        />}
        {page == 2 && <NumberQuestion
          num={4}
          question={t('survey.questions.3')}
          unitFunc={v => `${numberWithCommas(v)} ${t('survey.people')}`}
          min={0}
          max={1000}
          step={10}
          value={data.size}
          onChange={(e, v) => updateData("size", v)}
        />}
        {page == 3 && <CategoryQuestion 
          num={5}
          question={t('survey.questions.4')}
          categories={occupations}
          value={data.targetType}
          onChange={v => updateData("targetType", v)}
        />}
        {page == 3 && <MultiCategoryQuestion 
          num={6}
          question={t('survey.questions.5')}
          categories={locations}
          values={data.location}
          translate={false}
          onChange={v => updateData("location", _.xor(data.location, [v]))}
        />}
        {page == 3 && <NumberQuestion
          num={7}
          question={t('survey.questions.6')}
          unitFunc={v => `${numberWithCommas(v)} ${t('survey.people')}`}
          min={0}
          max={1000}
          step={10}
          value={data.targetSize}
          onChange={(e, v) => updateData("targetSize", v)}
        />}
        {page == 4 && <CategoryQuestion 
          num={8}
          question={t('survey.questions.7')}
          categories={["salary", "honor", "industry"]}
          value={data.preference}
          onChange={v => updateData("preference", v)}
        />}
        {page == 4 && <CategoryQuestion 
          num={9}
          question={t('survey.questions.8')}
          categories={["retired", "business", "working"]}
          value={data.future}
          onChange={v => updateData("future", v)}
        />}
        {page == 4 && <CategoryQuestion 
          num={10}
          question={t('survey.questions.9')}
          categories={["teamplayer", "independent"]}
          value={data.teamwork}
          onChange={v => updateData("teamwork", v)}
        />}
        {page == 4 && <CategoryQuestion 
          num={11}
          question={t('survey.questions.10')}
          categories={["organized", "spontaneous"]}
          value={data.style}
          onChange={v => updateData("style", v)}
        />}
      </Box>
    </Container>
  )
}

function Survey() {
  const classes = useStyles()
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [page, setPage] = useState()
  const [data, setData] = useState({
    period: 0,
    salary: 50,
    type: undefined,
    size: 0,
    targetType: undefined,
    location: [],
    targetSize: 0,
    preference: undefined,
    future: undefined,
    teamwork: undefined,
    style: undefined
  })
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const swiper = useRef(null)
  const matches = useMediaQuery(theme => theme.breakpoints.up('sm'));
  var database = firebase.database();

  const submit = () => {
    let dataRef = database.ref('survey').push()
    dataRef.set({
      email,
      phone,
      createdAt: Date.now(),
      ..._.pickBy(data, _.identity)
    }).then(() => {
      alert("설문 등록이 완료되었습니다!")
      navigate('/')
    })
  }

  const move = to => () => {
    if (to == 'prev') {
      swiper.current.prev()
    }
    else if (to == "next") {
      swiper.current.next()
    }
    setPage(swiper.current.getPos())
  }

  const LandingScreen = () => {
    const classes = useStyles()
    return (
      <Box color="#fff" display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.landing}>
        <Box m={2} p={2} pt={5} pb={5} className={classes.innerLanding}>
          <Typography variant="h4" paragraph>{t('survey.intro_title')}</Typography>
          <Typography variant="h6">
            {t('survey.intro.0')}
          </Typography>
          <Typography variant="h6">
            {t('survey.intro.1')}
          </Typography>
          <Typography variant="h6">
            {t('survey.intro.2')}
          </Typography>
          <Typography variant="h6">
            {t('survey.intro.3')}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" size="large" onClick={() => setPage(0)}>{t('survey.begin')}</Button>
      </Box>
    )
  }

  const renderSummary = () => {
    return (
      <Box color="#fff" display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.summary}>
        <Box m={2} p={2} pt={5} pb={2} className={classes.innerLanding}>
          <Typography variant="h4" paragraph>{t('survey.outro_title')}</Typography>
          <Typography variant="h6">
            {t('survey.outro.0')}
          </Typography>
          <Typography variant="h6">
            {t('survey.outro.1')}
          </Typography>
          <Box borderRadius={5} mt={3} mb={1} p={2} style={{background: "rgba(255, 255, 255, 0.8)"}}>
            <TextField 
              value={email} 
              onChange={e => setEmail(e.target.value)}
              label={t('survey.email')}
              variant="outlined"
              fullWidth
              style={{margin: "10px 0"}}
            />
            <TextField 
              value={phone} 
              onChange={e => setPhone(e.target.value)}
              label={t('survey.phone')}
              variant="outlined"
              fullWidth
              style={{margin: "10px 0"}}
            />
          </Box>
        </Box>
        <Button disabled={!email && !phone} variant="contained" color="primary" size="large" onClick={submit}>제출</Button>
      </Box>
    )
  }

  return (
    <Page title="Survey | Agency">
      <Header transparent={false} />
      <Box height="100vh">
        {page !== undefined ? <Box position="relative">
          <ReactSwipe 
            className={classes.slider}
            swipeOptions={{ continuous: false }}
            ref={swiper}
          >
            {_.range(5).map(p => {
              return (
                <div key={p} className={classes.slide}>
                  <SurveyPage page={p} data={data} setData={setData} />
                </div>
              )
            })}
            <div className={classes.slide}>
              {renderSummary()}
            </div>
          </ReactSwipe>
          {matches && page > 0 && <IconButton onClick={move('prev')} style={{position: "absolute", top: "50%", left: 10}}><ChevronLeft className={classes.iconButton} /></IconButton>}
          {matches && page < 5 && <IconButton onClick={move('next')} style={{position: "absolute", top: "50%", right: 10}}><ChevronRight className={classes.iconButton}  /></IconButton>}
        </Box>: <LandingScreen />}
      </Box>
    </Page>
  );
}

export default Survey;
