import { combineReducers } from 'redux';
import jobReducer from './jobReducer';
import companyReducer from './companyReducer';
import notiReducer from './notiReducer';

const allReducers = combineReducers({
  job: jobReducer,
  company: companyReducer,
  noti: notiReducer
});

export default allReducers;
