import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
  useHistory
} from "react-router-dom";
import { Fab } from '@mui/material';
import { Article as ArticleIcon } from '@mui/icons-material';

import Home from './routes/Home';
import Survey from './routes/Survey';
import Jobs from './routes/jobs';
import Candidates from './routes/candidates';
import Company from './routes/Company';

import Header from './components/Header';
import Uploader from './components/resume';
import Resume from './routes/Resume';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="jobs/*" element={<Jobs />} />
          <Route path="candidates/*" element={<Candidates />} />
          <Route path="/company/:companyId" element={<Company />} />
          <Route path="/resume/:id" element={<Resume />} />
        </Routes>
        <Uploader />
      </Router>
    </>
  );
}

export default App;
