import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  AppBar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Popover,
  Toolbar,
} from '@mui/material';
import { 
  Language, Menu as MenuIcon,
  Work as WorkIcon,
  People as PeopleIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1
  },
  logo: {
    fontFamily: "AllStarBv",
    fontSize: "4rem",
    lineHeight: 1,
    fontWeight: 1
  }
}));

const languages = {
  "ko": "한국어",
  "en": "English",
  "ko-KR": "한국어",
  "en-EN": "English"
}

export default function Header() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const color = location.pathname == "/" ? "transparent" : "default"
  const position = location.pathname == "/" ? "fixed" : "static"

  const [anchorEl, setAnchorEl] = useState();
  const [drawer, setDrawer] = useState(false);
  const handleClick = v => {
    i18n.changeLanguage(v)
    setAnchorEl()
  }

  return (
    <AppBar position={position} color={color}>
      <Toolbar>
        <Box display="flex" className={classes.title}>
          <Button component={RouterLink} to="/" size="small" className={classes.logo}>
            AGENCY
          </Button>
        </Box>
        <div>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton onClick={() => setDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }} flexDirection="row">
            <Button component={RouterLink} to="/jobs" size="large">Jobs</Button>
            <Button component={RouterLink} to="/candidates" size="large">Candidates</Button>
            <Button
              aria-label="language"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={e => setAnchorEl(e.currentTarget)}
              sx={{ ml: 8, py: 0.5 }}
              variant="outlined"
            >
              {languages[i18n.language]}
            </Button>
          </Box>
          <Popover
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl()}
          >
            <Box p={1}>
              <MenuItem onClick={() => handleClick('ko-KR')}>한국어</MenuItem>
              <MenuItem onClick={() => handleClick('en-EN')}>English</MenuItem>
            </Box>
          </Popover>
        </div>
      </Toolbar>
      <SwipeableDrawer
        anchor="right"
        open={drawer}
        onOpen={() => setDrawer(true)}
        onClose={() => {setDrawer(false)}}
      >
        <Box width={250} onClick={() => setDrawer(false)}>
          <Button sx={{m: 1}} component={RouterLink} to="/" size="small" className={classes.logo}>
            AGENCY
          </Button>
          <Divider />
          <List sx={{ p: 1 }}>
            <ListItem button component={RouterLink} to="/jobs"> 
              <ListItemIcon>
                <WorkIcon />
              </ListItemIcon>
              <ListItemText primary="Jobs" />
            </ListItem>
            <ListItem button component={RouterLink} to="/candidates"> 
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Candidates" />
            </ListItem>
          </List>
          <Divider />
          <List sx={{ p: 1 }}>
            <ListItem button onClick={() => handleClick('ko-KR')}> 
              <ListItemIcon>
                <Avatar sx={{ bgcolor: i18n.language.startsWith("ko") ? "primary.main" : "default", width: 28, height: 28 }}>가</Avatar>
              </ListItemIcon>
              <ListItemText primary="한국어" />
            </ListItem>
            <ListItem button onClick={() => handleClick('en-EN')}> 
              <ListItemIcon>
                <Avatar sx={{ bgcolor: i18n.language.startsWith("en") ? "primary.main" : "default", width: 28, height: 28 }}>En</Avatar>
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </AppBar>
  );
}