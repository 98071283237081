import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import {
  Grid, CircularProgress, Typography, Container, Card, Box, Rating, Button,
  Avatar, List, ListItemButton, ListItemText, ListItemAvatar, Link
} from '@mui/material';
import { AttachFile } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import Page from '../components/Page';

import { get } from '../utils/api';

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));

function Resume() {
  const { id } = useParams();
  const [ resume, setResume ] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    get(`resumes/${id}`).then((res) => {
      setResume(res.data);
      localStorage.removeItem('received')
    })
  }, [])
  if (resume) return (
    <Page title="Resume Feedback | Agency">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Typography variant="h2" gutterBottom>{t('review.label')}</Typography>
          <Typography variant="h4">{resume.title}</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{t('review.file')}</Typography>
          <CardStyle>
            {resume.files.map((file) => (
              <ListItemButton key={file.name} component={Link} href={file.url}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'orange' }}>
                    <AttachFile />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} secondary="Jan 9, 2014" />
              </ListItemButton>
            ))}
          </CardStyle>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{t('review.summary')}</Typography>
          <CardStyle>
            <Rating sx={{mb: 3}} value={resume.rating} precision={0.5} />
            <ReactMarkdown>{resume.summary}</ReactMarkdown>
          </CardStyle>
        </Box>
        <Box mt={3}>
          <Typography variant="h6">{t('review.recommendation')}</Typography>
          <CardStyle>
            <ReactMarkdown>{resume.recommendation}</ReactMarkdown>
          </CardStyle>
        </Box>
        <Box mt={3}>
          <Typography>{t('review.help')}</Typography>
        </Box>
      </Container>
    </Page>
  );
  else return (
    <CircularProgress sx={{m: 3}} />
  )
}

export default Resume;
