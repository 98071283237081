import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { styled } from '@mui/system';
import { 
  Container, Box, Avatar, CircularProgress, Typography, Card, Grid, LinearProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { get } from '../utils/api';
import Page from '../components/Page';
import { JobItem } from './jobs/Search';

const ProfileStyle = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  [theme.breakpoints.down('md')]: {
    alignItems: "center"
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
  marginBottom: theme.spacing(2)
}));

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));

export default function Company() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const [company, setCompany] = useState();
  const [loading, setLoading] = useState(true);
  const [searchedKeys, setSearchedKeys] = useState([]);

  useEffect(() => {
    get(`companies/${companyId}`).then((res) => {
      setCompany(res.data);
      console.log(res.data);
    })
  }, [])

  if (company) return (
    <Page title={`Company: ${company?.name} | Agency`}>
      <Container maxWidth="lg">
        <Box>
          <ProfileStyle>
            <AvatarStyle src={company.logo} />
            <Typography variant="h2">{company.name}</Typography>
            <Typography variant="h4" color="textSecondary">{company.note}</Typography>
          </ProfileStyle>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">{t('company.category')}</Typography>
          <CardStyle>
            <Typography variant="h6">{t(`company.categories.${company.category}`)}</Typography>
          </CardStyle>
        </Box>
        <Box mt={6}>
          <Typography variant="h5">{company.jobs.length} {t('job.search.result')}</Typography>
          <Grid container>
          {company.jobs.map((job) => 
            <Grid item xs={12} sm={6} md={4} key={job._id}>
              <JobItem job={job} onClick={() => navigate(`/jobs/${job._id}`)} />
            </Grid>
          )}
          </Grid>
        </Box>
      </Container>
    </Page>
  )
  else return (
    <CircularProgress sx={{m: 3}} />
  )
}