import './index.css';
import './utils/firebase';

import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

// redux
import { Provider } from 'react-redux';
import store from './redux/configureStore';

import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeConfig from './theme';
import reportWebVitals from './reportWebVitals';

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeConfig>
        <CssBaseline /> 
        <App />
      </ThemeConfig>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
