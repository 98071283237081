import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from "react-router-dom";
import { styled } from '@mui/system';
import { 
  Box, Checkbox, Button, Typography, IconButton, 
  Dialog, DialogTitle, DialogContent, Avatar, Card,
  List, ListItem, ListItemText, ListItemIcon,
  FormGroup, FormControlLabel
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';

import ReactMarkdown from 'react-markdown';
import Page from '../../components/Page';
import Snackbar from '../../components/Snackbar';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';

import { displayMoney, humanFileSize } from '../../utils/functions';
import { uploadResume } from '../../utils/database';
import { get } from '../../utils/api';

const CardStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  "& ul": {
    paddingLeft: theme.spacing(2)
  }
}));

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  marginRight: theme.spacing(1)
}));

const ButtonStyle = styled(Button)(({ theme}) => ({
  fontSize: "1.5rem",
  justifyContent: "center",
  alignItems: "center",
  marginTop: theme.spacing(1)
}))

const DropZoneStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderWidth: 4,
  borderRadius: 10,
  borderColor: "#ddd",
  borderStyle: "dashed",
  color: "#999"
}))

const ApplyDialog = (props) => {
  const { t, i18n } = useTranslation();
  const { jobId, open, onDismiss, onComplete } = props;
  const [selected, setSelected] = useState([])
  const [agree, setAgree] = useState(false)
  const [loading, setLoading] = useState(false);
  const onDrop = (files) => {
    setSelected(files)
  }
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 3,
    onDrop
  });
  
  const files = selected.map((file, idx) => (
    <ListItem 
      dense 
      key={file.path}
      secondaryAction={
        <IconButton 
          edge="end"
          aria-label="delete"
          onClick={() => {
            setSelected([
              ...selected.slice(0, idx),
              ...selected.slice(idx+1)
            ])
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemIcon>
        <InsertDriveFileIcon />
      </ListItemIcon>
      <ListItemText 
        primary={`${file.path} | ${humanFileSize(file.size)}`}
      />
    </ListItem>
  ));

  const handleUpload = () => {
    setLoading(true);
    uploadResume(jobId, selected).then(() => {
      setLoading(false);
      setSelected([]);
      setAgree(false);
      onComplete();
    })
  }

  return (
    <Dialog 
      fullWidth
      maxWidth="md"
      open={open} 
      onBackdropClick={onDismiss}
    >
      <DialogTitle style={{fontSize: '1.5rem'}}>
        {t("apply.title")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">{t("apply.guide1")}</Typography>
        <Typography variant="body2">{t("apply.guide2")}</Typography>
        <Typography variant="body2">{t("apply.guide3")}</Typography>
        <section className="container">
          {selected.length == 0 && <DropZoneStyle {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 80 }}/>
            <p>{t("apply.upload")}</p>
          </DropZoneStyle>}
          {selected.length > 0 && <Box mt={2} mb={2}>
            <Typography variant="h6">{t("apply.files")}</Typography>
            <List>{files}</List>
          </Box>}
        </section>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox 
              value={agree}
              onChange={(e, checked) => setAgree(checked)}
            />}
            label={
              <Typography variant="overline"  >
                {t("apply.agreement")}
              </Typography>
            }
          />
        </FormGroup>
        <LoadingButton 
          fullWidth
          loading={loading}
          variant="contained"
          disabled={!agree || selected.length == 0}
          onClick={handleUpload}
          style={{marginTop: 8}}
        >
          업로드
        </LoadingButton>
      </DialogContent>
    </Dialog>
  )
}

export default function Job() {
  const { t, i18n } = useTranslation();
  const { jobId } = useParams();
  const [job, setJob] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [snack, setSnack] = useState();
  useEffect(() => {
    get(`jobs/${jobId}`).then((res) => {
      setJob(res.data);
      window.scrollTo(0,0);
    })
  }, [])
  if (job) return (
    <Page title={`Job: ${job.title} | Agency`}>
      <Box>
        <Box mt={3}>
          <Typography variant="h2" gutterBottom>{job.title}</Typography>
          <Typography variant="h5" color="textSecondary">- {t(`job.categories.${job.category}`)}</Typography>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">{t('job.salary')}</Typography>
          <CardStyle>
            <Typography variant="h6">{displayMoney(i18n.language, false)(job.salary[0])} ~ {displayMoney(i18n.language)(job.salary[1])}</Typography>
          </CardStyle>
        </Box>
        <Box mt={3}>
          <Typography variant="h5">{t('job.company')}</Typography>
          <CardStyle>
            <Button variant="inherit" component={RouterLink} to={`/company/${job.company._id}`} mb={2} sx={{display: 'flex', alignSelf: 'flex-start', mb: 1, p: 1}}>
              <AvatarStyle src={job.company.logo} />
              <Typography variant="h6">{job.company.name}</Typography>
            </Button>
            <Typography variant="body1" color="textSecondary">{job.company.note}</Typography>
          </CardStyle>
        </Box>
        {Boolean(job.summary) && <Box mt={3}>
          <Typography variant="h5">{t('job.summary')}</Typography>
          <CardStyle>
            <Typography variant="h6">{t(`company.categories.${job.company.category}`)}</Typography>
            <ReactMarkdown>{job.summary}</ReactMarkdown>
          </CardStyle>
        </Box>}
        {Boolean(job.responsibilities) && <Box mt={3}>
          <Typography variant="h5">{t('job.responsibilities')}</Typography>
          <CardStyle>
            <ReactMarkdown>{job.responsibilities}</ReactMarkdown>
          </CardStyle>
        </Box>}
        {Boolean(job.qualifications) && <Box mt={3}>
          <Typography variant="h5">{t('job.qualifications')}</Typography>
          <CardStyle>
            <ReactMarkdown>{job.qualifications}</ReactMarkdown>
          </CardStyle>
        </Box>}
        {Boolean(job.note) && <Box mt={3}>
          <Typography variant="h5">{t('job.note')}</Typography>
          <CardStyle>
            <ReactMarkdown>{job.note}</ReactMarkdown>
          </CardStyle>
        </Box>}
      </Box>
      <Box mt={3}>
        <ButtonStyle
          fullWidth
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          {t('apply.title')}
        </ButtonStyle>
        {/* <Typography variant="overline" color="textSecondary">
          Upload your resume if you are interested in this job.
        </Typography> */}
      </Box>
      <ApplyDialog
        jobId={jobId}
        open={showDialog}
        onDismiss={() => setShowDialog(false)}
        onComplete={() => {
          setSnack(t("apply.complete"))
          setShowDialog(false)
        }}
      />
      <Snackbar
        open={Boolean(snack)}
        handleClose={() => setSnack()}
        message={snack}
      />
    </Page>
  )
  else return (
    <Typography>Loading...</Typography>
  )
}