import moment from 'moment';
import { post } from './api';
import { firebaseAuth, dbRef, stRef } from './firebase';

export async function uploadResume(files, email, phone) {

  const resumeKey = dbRef.child('resumes').push().key
  const promises = []
  files.forEach(file => {
    const components = file.name.split('.')
    const ext = components.slice(-1)
    const name = components.slice(0, -1).join('.')
    const fileName = `${name}_${moment().unix()}.${ext}`
    promises.push(new Promise((resolve, reject) => {
      stRef
        .child('resumes')
        .child(resumeKey)
        .child(fileName)
        .put(file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            resolve({
              name: file.name,
              type: file.type,
              size: file.size,
              url: downloadURL
            })
          });
        })
    }))
  })

  const urls = await Promise.all(promises);
  return new Promise((resolve, reject) => {
    post(`resumes/${resumeKey}`, {body: {
      files: urls, email, phone
    }}).then(() => {
      resolve(resumeKey);
    })
  })
}

export const listenResume = (resumeKey, callback) => {
  return dbRef.child('resumes').child(resumeKey).on('value', callback);
}

export const unlistenResume = (resumeKey) => {
  return dbRef.child('resumes').child(resumeKey).off('value');
}

export const getResume = (resumeKey) => {
  return dbRef.child('resumes').child(resumeKey).get();
}